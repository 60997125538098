<template>
<div>
        <loading :active.sync="loading"></loading>
        <!--<link rel="stylesheet" href="https://unpkg.com/@coreui/icons/css/all.min.css">-->
        <div class="row"><div class="col-md-12">
          <b-card style="max-width:1050px;">
            <template v-slot:header>
            <div class="row">
                <div class="col-md-4">
                <strong>Custos Diretos - PRODUTOS</strong>
                </div>
                <div class="col-md-8">
                  <div align="right">
                  <a
                  class="btn btn-link" 
                  href="javascript:;"
                  @click="showModalSimples()" >
                  <CIcon name="cil-cloud-upload" />&nbsp;<small>Importar</small>
              </a>
                  <download-excel
                  class="btn btn-link" 
                  :data   = "matExcel"
                  name = "precificador.xls"
                   >
                  <CIcon name="cil-cloud-download" />&nbsp;<small>Exportar</small>
              </download-excel>
                <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
                  </div>
                </div>
            </div>
          </template>
          <b-row>
          <b-col lg="6" md="12" sm="12" class="my-1">
            <b-form-group
              label="Busca"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0 mt-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Digite para procurar produtos..." 
                  v-on:keyup="searchItems"
                ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
          <b-card-body>
                <b-alert :show="alertaFaturamento" variant="danger">
                  <p><strong>Alerta de média de faturamento</strong></p>
                  <p>O resultado do Gera Preço pode ser impreciso caso não informe mês a mês o faturamento formal de sua empresa.</p>
                  <a href="/#/configuracoes/impostos" class="btn btn-info">Alimentar planilha de faturamento</a>
                </b-alert>
                <b-tabs>
                  <b-tab :title="qtdeProdutos" active>
                    <div class="table-responsive">
                        <table class="table table-hover table-sm">
                            <thead>
                            <tr>
                              <th class="grey" :style="colunaFixa1"></th>
                              <th data-v-step="1" class="grey" :style="colunaFixa1"></th>
                              <th class="grey" :style="colunaFixa2"></th>
                              <th class="grey" :style="colunaFixa3"></th>
                              <th class="grey" :style="colunaFixa4">Identificadores</th>
                              <th class="grey" :style="colunaFixa5"></th>
                              <th class="grey" :style="colunaFixa6">1</th>
                              <th class="grey" :style="colunaFixa7">2</th>
                              <th class="grey" id="tooltip-a" :style="colunaFixa8"><u>3</u></th>
                              <th class="grey" id="tooltip-1" :style="colunaFixa9"><u>4</u></th>
                              <th class="grey" :style="colunaFixa1"></th>
                            </tr>
                            <tr>
                                <th :style="colunaFixa1"></th>
                                <th :style="colunaFixa1"></th>
                                <th class="centro" :style="colunaFixa2">Linha</th>
                                <th class="centro" :style="colunaFixa3">Cód.</th>
                                <th class="centro" :style="colunaFixa4">Produto</th>
                                <th class="centro" :style="colunaFixa5">Unid. Venda</th>
                                <th class="centro" :style="colunaFixa6">Insumos</th>
                                <th class="centro" :style="colunaFixa7">Mão de obra</th>
                                
                                <th class="centro" :style="colunaFixa8">Perda</th>
                                <th class="centro" :style="colunaFixa9">Custo Direto</th>
                                <th class="centro" :style="colunaFixa1">Dup.</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(prdItem, index) in prd" :key="index">
                                    <th :style="colunaFixa1">
                                       <b-form-checkbox
                                          v-model="prd[index].prd_active" 
                                          switch 
                                          @change.native="editar(index)"
                                        ></b-form-checkbox>
                                    </th>
                                    <th :style="colunaFixa1">
                                        <!--<b-form-checkbox
                                        v-model="prd[index].prd_active" 
                                        size="sm" 
                                        switch 
                                        @change.native="editar(index)"
                                        ></b-form-checkbox>-->
                                        <div class="numeracao">{{ (((index + 1) < 10) ? String('0' + (index + 1)) : (index + 1)) }}</div>
                                    </th>
                                    <th :style="colunaFixa2" data-title="Linha">
                                        <b-form-select style="background-image:none;" class="form-control verde" @change="editar(index, true)" v-model="prd[index].pln_ID" :options="linhaProdutos"></b-form-select>
                                    </th>
                                    <th :style="colunaFixa3" data-title="Cód.">
                                        <b-form-input class="verde" @blur="editar(index, true)" v-model="prd[index].prd_code"></b-form-input>
                                    </th>
                                    <th :style="colunaFixa4" data-title="Produto">
                                        <b-form-input class="verde" @blur="editar(index, true)" v-model="prd[index].prd_name"></b-form-input>
                                    </th>
                                    <td data-title="Unidade">
                                      <b-form-input class="verde" @blur="editar(index, true)" v-model="prd[index].prd_descr"></b-form-input>
                                    </td>
                                    <td data-title="Insumos">
                                        <vue-autonumeric class="form-control amarelo direita editavel" @click.native="i(index)" style="font-size:10px; cursor: pointer; box-shadow: 1px 1px 3px blue;" :options="dinheiro" readonly v-model="prd[index].prd_insumos_total"></vue-autonumeric>
                                        <span class="editando"><CIcon name="cil-pencil"/></span>
                                    </td>
                                    <td data-title="Mão de obra">
                                        <vue-autonumeric class="form-control amarelo direita editavel" @click.native="mo(index)" style="font-size:10px; cursor: pointer; box-shadow: 1px 1px 3px blue;" :options="dinheiro" readonly v-model="prd[index].prd_maodeobra_total"></vue-autonumeric>
                                        <span class="editando"><CIcon name="cil-pencil"/></span>
                                    </td>
                                    <td data-title="Perda %" v-if="prd[index].prd_tipo !== 2">
                                      <vue-autonumeric @blur.native="editar(index, true)" class="form-control verde centro" :options="percentual" v-model="prd[index].prd_perda"></vue-autonumeric>
                                    </td>
                                    <td data-title="Custo Direto">
                                        <vue-autonumeric class="form-control amarelao direita" style="font-size:10px;" :options="dinheiro" readonly v-model="prd[index].prd_custodireto_total"></vue-autonumeric>
                                    </td>
                                    <td data-title="Duplicar produto" style="text-align:center;">
                                      <button class="btn btn-link" title="Duplicar produto" @click="dup(prd[index].prd_ID)"><CIcon name="cil-clone" /></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr id="adicionando">
                                    <th></th>
                                    <th></th>
                                    <th data-title="Linha" data-v-step="4">
                                      <b-form-select class="form-control" v-model="prdLinha" aria-placeholder="Linha" :options="linhaProdutos"></b-form-select>
                                    </th>
                                    <th data-title="Cód. Produto"><b-form-input v-model="prdCode" placeholder="Cód. Produto" data-v-step="3"></b-form-input></th>
                                    <th data-title="Nome do Produto"><b-form-input v-model="prdName" placeholder="Nome do Produto" data-v-step="2"></b-form-input></th>
                                    <th data-title="Unid. de Venda"><b-form-input v-model="prdDescr" placeholder="Unid. de Venda" data-v-step="5"></b-form-input></th>
                                    <th colspan="5" data-title="Criar produto"><button data-v-step="7" class="btn btn-success" :disabled="adicionou" @click="adicionar()">Adicionar</button></th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                  </b-tab>
                  <b-tab title="Inativos">
                    <table class="table table-hover">
                              <thead>
                              <tr>
                                  <th style="width:3%"></th>
                                  <th style="width:12%">Linha</th>
                                  <th style="width:10%">Cód. Produto</th>
                                  <th style="width:30%">Nome do Produto</th>
                                  <th style="width:10%">Unid. de Venda</th>
                                  <th style="width:5%">Perda</th>
                                  <th style="width:20%" data-v-step="10"></th>
                              </tr>
                              </thead>

                              <tbody>
                                  <tr v-for="(materialItem2, index) in prdoff" :key="index">
                                      <td data-title="Ativar?">
                                        <b-form-checkbox
                                          v-model="prdoff[index].prd_active" 
                                          switch 
                                          @change.native="reativarItem(prdoff[index].prd_ID)"
                                          size="sm" 
                                        ></b-form-checkbox>
                                      </td>
                                      <td data-title="Linha">
                                        <b-form-select class="form-control verde" readonly v-model="prdoff[index].pln_ID" :options="linhaProdutos"></b-form-select>
                                      </td>
                                      <td data-title="Cód. Produto">
                                        <b-form-input class="verde" readonly v-model="prdoff[index].prd_code"></b-form-input>
                                      </td>
                                      <td data-title="Nome do Produto">
                                        <b-form-input class="verde" readonly v-model="prdoff[index].prd_name"></b-form-input>
                                      </td>
                                      <td data-title="Unid. de Venda">
                                        <b-form-input class="verde" readonly v-model="prdoff[index].prd_descr"></b-form-input>
                                      </td>
                                      <td data-title="Perda">
                                        <vue-autonumeric class="form-control amarelo centro" :options="percentual" v-model="prdoff[index].prd_perda" readonly></vue-autonumeric>
                                      </td>
                                      <td>
                                        <b-button variant="danger" size="sm" @click="remocaoProduto(prdoff[index].prd_ID)">Remover</b-button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                  </b-tab>
                </b-tabs>
          </b-card-body>
          </b-card>
          <b-modal size="lg" ref="modal-simples" hide-footer title="Múltiplos produtos">
            <b-alert variant="info" show dismissible>
              <p>Utilize o modelo de planilha abaixo para subir mais de um produto de uma vez.<br>
              Você pode utilizar o EXCEL para copiar e colar aqui.<br>
              Atenção para a coluna Linha. Caso não exista, o mesmo será criado automaticamente.</p>
            </b-alert>
            <b-alert variant="danger" v-if="isMobile()" show>
        <p><strong>Desculpe!</strong> Este recurso no momento é incompatível com dispositivos móveis.</p>
      </b-alert>
            <hot-table ref="dataTables" :data="dataTable" :settings="dataTableSet"></hot-table>
            <div class="row">
              <div class="col-md-8">
                <b-button variant="success" :disabled="adicionou" @click="salvaSimplesData()">Salvar planilha</b-button>
                &nbsp;&nbsp;<b-button variant="danger" size="sm" @click="removeSimplesData()">Limpar</b-button>
              </div>
              <div class="col-md-4">
                <b-progress :value="vTbl" :max="maxTbl" show-progress animated></b-progress>
              </div>
            </div>
          </b-modal>
            <b-tooltip target="tooltip-a" triggers="hover">
            Percentual que representa a perda de produtos fabricados
            </b-tooltip>
            
            
            <b-tooltip target="tooltip-1" triggers="hover">
            Insumos + Mão de Obra + Perda (1 + 2 + 3)
            </b-tooltip>
            
          </div>
        </div>
        <a v-if="isMobile()" href="javascript:;" @click="scrollBusca()" class="btnfloat">
      <CIcon name="cil-find-in-page" class="my-btnfloat" />
    </a>
    <b-modal ref="my-insumo" hide-footer no-close-on-backdrop no-close-on-esc size="lg" v-model="myInsumo">
      <insumo ref="insumodiv" :id="idinsumo" :embed="true" :key="componentKey"></insumo>
    </b-modal>
    <b-modal ref="my-mo" hide-footer no-close-on-backdrop no-close-on-esc size="lg" v-model="myMo">
      <maodeobra ref="modiv" :id="idmo" :embed="true" :key="componentKey"></maodeobra>
    </b-modal>
    <b-modal size="lg" id="ajuda" hide-footer title="Custos Diretos - Produtos">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/OIuJ5wPC0zs?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
    <div class="adicionar-foot"><button id="naoesquecadesalvar" class="btn btn-success" title="Clique aqui para salvar as alterações" :disabled="indexes.length === 0" @click="salvartudo()">SALVAR ALTERAÇÕES</button></div>
        <b-tooltip :show="naoesqueca" target="naoesquecadesalvar">
          <p><strong>Não esqueça de salvar!</strong></p>
          <p>Clique aqui para salvar as modificações.</p>
        </b-tooltip>
</div>
</template>
<style>
table.htCore tr td:nth-child(5) {
  background-color: #CCFFCC !important;
}
</style>
<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'
import Insumo from './MaterialUsoModal'
import Maodeobra from './MaodeobraUsoModal'
import { HotTable } from '@handsontable/vue'

export default {
  data () {
    return {
      loading: false,
      naoesqueca: false,
      indexes: [],
      colunaFixa1: 'min-width:10px !important; width:10px !important;',
      colunaFixa2: 'min-width:90px !important; width:90px !important;',
      colunaFixa3: 'min-width:90px !important; width:90px !important;',
      colunaFixa4: 'min-width:200px !important; width:200px !important;',
      colunaFixa5: 'min-width:90px !important; width:90px !important;',
      colunaFixa6: 'min-width:90px !important; width:90px !important;',
      colunaFixa7: 'min-width:90px !important; width:90px !important;',
      colunaFixa8: 'min-width:50px !important; width:50px !important;',
      colunaFixa9: 'min-width:90px !important; width:90px !important;',
      autoH: 'height:calc(100vh - 312px)',
      prd: [],
      prdOriginal: [],
      linhaProdutos: [],
      matExcel: [],
      materialItem: [],
      maxProd: null,
      sessao: '',
      prdoff: [],
      ativa: 0,
      prdFull: [],
      prdName: '',
      prdDescr: '',
      prdCode: '',
      prdValor: 0,
      prdActive: 1,
      prdLinha: 0,
      prdTipo: 1,
      filter: '',
      carregando: '<div align="right"><img src="/img/loading.gif" alt="Aguarde..." /></div>',
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      percentual: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      percentual2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 1
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      qtdeProdutos: 'Produtos',
      qtdePreparos: 'Preparos',
      prep: [],
      comentarios: '',
      mcminima: 0,
      ocampo: ['', 'Outros 1', 'Outros 2', 'Comissão 2'],
      alertaFaturamento: false,
      encargo_val: 0,
      idinsumo: null,
      insumos_preparo: [],
      idmo: null,
      componentKey: 0,
      myInsumo: false,
      myMo: false,
      adicionou: false,
      dataTable: [],
      opcoesLinha: [],
      opcoesLinhaIds: [],
      vTbl: 0,
      maxTbl: 0,
      dataTableSet: {
        colHeaders: ['Linha', 'Código', 'Nome do Produto', 'Unidade de Venda'],
        rowHeaders: true,
        minSpareRows: 2,
        width: 800,
        height: 250,
        colWidths: [100, 100, 200, 100],
        columns: [
          {
            type: 'autocomplete',
            source: this.opcoesLinha,
            strict: true,
            allowInvalid: true
          },
          {}, {}, {}
        ],
        licenseKey: 'non-commercial-and-evaluation'
      }
    }
  },
  watch: {
    myInsumo: function () {
      if (!this.myInsumo) {
        this.idinsumo = null
        this.componentKey += 1
        this.recarregar(true)
      }
    },
    myMo: function () {
      if(!this.myMo) {
        this.idmo = null
        this.componentKey += 1
        this.recarregar(true)
      }
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    /* if (screen.width <= 760) {
      this.colunaFixa1 = 'left: 0px;'
      this.colunaFixa2 = 'left: 0px;'
      this.colunaFixa3 = 'left: 0px;'
      this.colunaFixa4 = 'left: 0px;'
      this.autoH = 'height:100%'
    } */
    this.verificaSessao()
  },
  filters: {
    grana: function (num) {
      if (typeof num !== 'undefined') {
        return String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      }
    },
    somarArray: function (value) {
      var ret = 0
      for (var i = 0; i < value.length; i++) {
        ret = ret + parseFloat(value[i])
      }
      return ret
    }
  },
  methods: {
    isMobile: function () {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    scrollBusca: function () {
      document.getElementById('filterInput').focus()
      window.scrollTo(0, 100)
    },
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('PRECIFICADOR'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        this.loading = true
        this.linhaProdutos = []
        this.opcoesLinha = []
        this.opcoesLinhaIds = []
        this.prd = []
        this.prdoff = []
        this.listar('pro_Produto_Linha', 'pln_name', 'pln_active = 1').then(
          (r) => {
            for (var i = 0; i < r.length; i++) {
              this.linhaProdutos.push({value: r[i].pln_ID, text: r[i].pln_name })
              this.opcoesLinha.push(r[i].pln_name)
              this.opcoesLinhaIds[r[i].pln_ID] = r[i].pln_name
            }
            this.dataTableSet.columns[0].source = this.opcoesLinha
            this.listar('pro_Produto', 'prd_name', '(prd_active = 0) AND (prd_tipo = 1)').then(
              (rrr) => {
                for (var i = 0; i < rrr.length; i++) {
                  rrr[i].prd_active = (rrr[i].prd_active === 1)
                }
                this.prdoff = rrr
              }
            )
            // Buscando o total de produtos permitidos
            if (this.$session.get('grupo') > 0) {
              this.campo('gp_Grupo', 'grp_registros', 'grp_ID = ' + this.$session.get('grupo')).then(
                (g) => {
                  this.maxProd = g
                },
                (g) => {
                  this.maxProd = null
                }
              )
            }
          },
          (r) => {
            this.$notify({
              type: 'error',
              title: 'Linha de produtos vazia',
              text: 'Adicione algumas linhas de produtos, em configurações, para um melhor funcionamento do sistema.'
            })
          }
        )
        this.verificaFaturamento()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    adicionar: function () {
      if (this.prdCode === '' || this.prdName === '') {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      this.adicionou = true
      // Verifica se já existe
      this.campo('pro_Produto', 'prd_ID', '(prd_code = ' + this.prdCode + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.prdCode) {
            this.$swal('Já existe um registro com este codigo: ID ' + response + '')
          } else {
            this.$swal('Erro ao inserir: Já existe um produto/preparo com esse código. ' + JSON.stringify(response))
          }
          this.adicionou = false
        }, (response) => {
          if (this.maxProd > 0 && this.maxProd <= this.prd.length) {
            this.$swal('Você atingiu o limite máximo de produtos/preparos cadastrados para o seu plano.')
            return false;
          }
          this.loading = true
          this.inserir('pro_Produto', {resource: [ {prd_code: this.prdCode, pln_ID: this.prdLinha, prd_name: this.prdName, prd_descr: this.prdDescr, prd_perda: 0, prd_tipo: this.prdTipo, prd_valor: this.prdValor, prd_active: 1} ]}).then(
            (response) => {
              // Se for pre-preparo, cria o material associado ao produto
              if (this.prdTipo === 2 || this.prdTipo === 3) {
                var a = response
                this.inserir('pro_Material', {resource: [{ prd_ID: a[Object.keys(a)[0]], mat_tipo_ID: 1, mat_name: this.prdName }]}, '', '1')
              }
              this.prdName = ''
              this.prdCode = ''
              this.prdValor = 0
              this.prdLinha = 0
              this.verificaSessao()
              this.adicionou = false
            },
            (response) => {
              this.$swal('Erro ao inserir: ' + JSON.stringify(response))
              this.loading = false
              this.adicionou = false
            }
          )
        }
      )
    },
    editar: function (index, fast) {
      if (fast) {
        this.loading = false
        this.indexes.push(index)
        if (this.indexes.length === 1) {
          this.naoesqueca = true
        }
        return false
      }
      this.indexes = []
      if (this.prd[index].prd_code === '' || this.prd[index].prd_name === '') {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      // Verifica se já existe
      this.campo('pro_Produto', 'prd_ID', '(prd_code = ' + this.prd[index].prd_code + ') AND (prd_name = ' + this.prd[index].prd_name + ') AND (prd_ID != ' + this.prd[index].prd_ID + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.prdCode) {
            this.$swal('Já existe um registro com este codigo: ID ' + response + '')
          } else {
            this.$swal('Erro ao atualizar: Produto/preparo com mesmo nome e código já existe.')
          }
        }, (response) => {
          this.loading = true
          this.atualizar('pro_Produto', {resource: [ {prd_code: this.prd[index].prd_code, pln_ID: this.prd[index].pln_ID, prd_name: this.prd[index].prd_name, prd_descr: this.prd[index].prd_descr, prd_perda: this.prd[index].prd_perda, prd_tipo: this.prd[index].prd_tipo, prd_valor: this.prd[index].prd_valor, prd_active: this.prd[index].prd_active} ]}, 'prd_ID = ' + this.prd[index].prd_ID).then(
            (response) => {
              // Ao editar, cria ou modifica o material associado ao produto (pré-preparo)
              if ((this.prd[index].prd_tipo === 2 || this.prd[index].prd_tipo === 3) && parseInt(this.prd[index].active) === 1) {
                // Verifica se existe
                this.campo('pro_Material', 'mat_ID', '(prd_ID = ' + this.prd[index].prd_ID + ')').then(
                  (t) => {
                    if (t > 0) {
                      // ja tem
                      this.atualizar('pro_Material', {resource: [{ mat_name: this.prd[index].prd_name }]}, 'mat_ID = ' + t, '', '1')
                    } else {
                      // cria
                      this.inserir('pro_Material', {resource: [{ prd_ID: this.prd[index].prd_ID, mat_tipo_ID: 1, mat_name: this.prd[index].prd_name }]}, '', '1')
                    }
                  },
                  (t) => {
                    // cria
                    this.inserir('pro_Material', {resource: [{ prd_ID: this.prd[index].prd_ID, mat_tipo_ID: 1, mat_name: this.prd[index].prd_name }]}, '', '1')
                  }
                )
              } else {
                this.remover('pro_Material', '(prd_ID = ' + this.prd[index].prd_ID + ')')
              }
              if (fast) {
                this.loading = false
              } else {
                this.verificaSessao()
              }
            },
            (response) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    },
    editarloop: function (index) {
      if (this.prd[index].prd_code === '' || this.prd[index].prd_name === '') {
        return -1
      }
      // Verifica se já existe
      return this.campo('pro_Produto', 'prd_ID', '(prd_code = ' + this.prd[index].prd_code + ') AND (prd_name = ' + this.prd[index].prd_name + ') AND (prd_ID != ' + this.prd[index].prd_ID + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.prdCode) {
            return -3
          } else {
            return -4
          }
        }, (response) => {
          this.loading = true
          return this.atualizar('pro_Produto', {resource: [ {prd_code: this.prd[index].prd_code, pln_ID: this.prd[index].pln_ID, prd_name: this.prd[index].prd_name, prd_descr: this.prd[index].prd_descr, prd_perda: this.prd[index].prd_perda, prd_tipo: this.prd[index].prd_tipo, prd_valor: this.prd[index].prd_valor, prd_active: this.prd[index].prd_active} ]}, 'prd_ID = ' + this.prd[index].prd_ID, '', '1').then(
            (response) => {
              // Ao editar, cria ou modifica o material associado ao produto (pré-preparo)
              if ((this.prd[index].prd_tipo === 2 || this.prd[index].prd_tipo === 3) && parseInt(this.prd[index].active) === 1) {
                // Verifica se existe
                return this.campo('pro_Material', 'mat_ID', '(prd_ID = ' + this.prd[index].prd_ID + ')').then(
                  (t) => {
                    if (t > 0) {
                      // ja tem
                      return this.atualizar('pro_Material', {resource: [{ mat_name: this.prd[index].prd_name }]}, 'mat_ID = ' + t, '', '1').then(
                        (a) => {
                          return a
                        },
                        (a) => {
                          return 0
                        }
                      )
                    } else {
                      // cria
                      return this.inserir('pro_Material', {resource: [{ prd_ID: this.prd[index].prd_ID, mat_tipo_ID: 1, mat_name: this.prd[index].prd_name }]}, '', '1').then(
                        (a) => {
                          return a
                        },
                        (a) => {
                          return 0
                        }
                      )
                    }
                  },
                  (t) => {
                    // cria
                    return this.inserir('pro_Material', {resource: [{ prd_ID: this.prd[index].prd_ID, mat_tipo_ID: 1, mat_name: this.prd[index].prd_name }]}, '', '1').then(
                      (a) => {
                        return a
                      },
                      (a) => {
                        return 0
                      }
                    )
                  }
                )
              } else {
                return this.remover('pro_Material', '(prd_ID = ' + this.prd[index].prd_ID + ')').then(
                  (a) => {
                    return a
                  },
                  (a) => {
                    return 0
                  }
                )
              }
            },
            (response) => {
              return -2
            }
          )
        }
      )
    },
    salvartudo: function () {
      this.loading = true
      if (this.indexes.length === 0) {
        this.loading = false
        this.naoesqueca = false
        this.$swal('Não há nada a ser salvo aqui.')
        return false
      }
      var i = this.indexes.filter((v, i, a) => a.indexOf(v) === i)
      var index = 0
      var p = []
      var ind = []
      for (var idx in i) {
        index = i[idx]
        p.push(this.editarloop(index))
        ind.push(index)
      }
      Promise.all(p).then(
        (r) => {
          console.log(r)
          for (var y = 0; y < r.length; y++) {
            if (r[y] === -1) {
              this.loading = false
              this.$swal('Falha ao salvar', 'Erro no registro da linha ' + (ind[y] + 1) + ': todos os campos são obrigatórios.', 'error')
              return false
            }
            if (r[y] === -2) {
              this.loading = false
              this.$swal('Falha ao salvar', 'Erro no registro da linha ' + (ind[y] + 1) + ': falha interna ao salvar registro.', 'error')
              return false
            }
            if (r[y] === -3) {
              this.loading = false
              this.$swal('Falha ao salvar', 'Erro no registro da linha ' + (ind[y] + 1) + ': já existe um registro com este código.', 'error')
              return false
            }
            if (r[y] === -4) {
              this.loading = false
              this.$swal('Falha ao salvar', 'Erro no registro da linha ' + (ind[y] + 1) + ': já existe um produto/preparo com mesmo nome e/ou código.', 'error')
              return false
            }
          }
          this.loading = false
          this.indexes = []
          this.naoesqueca = false
          this.recarregar(true)
        },
        (r) => {
          this.loading = false
          this.naoesqueca = false
          this.$swal('Falha ao salvar um ou mais registros. Verifique sua conexão.')
          return false
        }
      )
    },
    recarregar: function (simples) {
      this.loading = true
      // Listando preparos e produtos
      this.prep = []
      this.prd = []
      this.insumos_preparo = []
      this.campo('pro_Encargo', 'enc_total_imposto', '(enc_active = 1)').then(
        (en) => {
          // Busca os valores de insumos
          this.listar('pro_Material', 'prd_ID', '(prd_ID > 0)').then(
            (x) => {
              for (var i = 0; i < x.length; i++) {
                this.insumos_preparo[x[i].prd_ID] = x[i].mat_custohora
              }
              var usoParams = {
                g: this.$session.get('grupo'),
                en: en,
                dash: 0,
                tipo: 1
              }
              this.$http.post(this.URLApp + 'tabelas/filtroProdutoGeraPreco', usoParams).then(
                (r) => {
                  this.montaGeraPreco(r.body, simples)
                  if (r.body.length === 0) {
                    this.$swal('Nenhum produto cadastrado', 'O precificador só é acessível caso preencha todas as tabelas necessárias para compor o produto desejado. Utilize esta tela para criar seus produtos e o menu TABELAS para criar insumos e mão de obra.', 'error')
                    this.$router.push('/configuracoes/produtos')
                  }
                  this.loading = false
                },
                (r) => {
                  this.$swal('Nenhum produto detectado ou falha ao buscar produtos', 'O precificador só é acessível caso preencha todas as tabelas necessárias para compor o produto desejado. Utilize esta tela para criar seus produtos e o menu TABELAS para criar insumos e mão de obra.', 'error')
                  this.$router.push('/configuracoes/produtos')
                  this.loading = false
                }
              )
            }
          )
        }, 
        (en) => {
          this.$notify({
            type: 'error',
            title: 'Encargos indefinidos',
            text: 'É necessário definir os encargos em Configurações.'
          })
        }
      )
    },
    montaGeraPreco: function (r, simples) {
      this.prep = []
      this.prd = []
      this.dataTable = []
      for (var i = 0; i < r.length; i++) {
        if (r[i].prd_maodeobra_total === null) {
          // Cria a "mao de obra zero"
          // this.inserir('pro_Produto_Maodeobra', {resource: [ {prd_ID: r[i].prd_ID, mao_ID: 1, pmo_num: 1, pmo_qtde: 0, pmo_medida: 'hora', pmo_active: 1} ] }, '', '1')
          r[i].prd_maodeobra_total = 0
        }
        if (r[i].prd_insumos_total === null) {
          // this.inserir('pro_Produto_Material', {resource: [ {prd_ID: r[i].prd_ID, pmt_mat_ID: 1, pmt_qtde: 0, pmt_active: 1} ] }, '', '1')
          r[i].prd_insumos_total = 0
        }
        r[i].prd_precosugerido = 0
        r[i].total_cv = (r[i].custo_direto_total + r[i].prd_outros_total)
        if (parseInt(r[i].prd_tipo) === 2) {
          r[i].prd_insumos_total = this.insumos_preparo[r[i].prd_ID]
          r[i].prd_custodireto_total = this.insumos_preparo[r[i].prd_ID] + r[i].prd_maodeobra_total
          this.prep.push(r[i])
        } else {
          r[i].prd_active = (r[i].prd_active === 1)
          this.prd.push(r[i])
        }
        this.matExcel.push({
          Linha: r[i].prd_linha_name,
          Nome: r[i].prd_name,
          Unid_Prod: r[i].prd_descr,
          Cod: r[i].prd_code,
          Insumos: (r[i].prd_insumos_total ? r[i].prd_insumos_total.toLocaleString('pt-BR', {minimumFractionDigits: 3, style: 'currency', currency: 'BRL'}) : 0),
          Mao_de_obra: (r[i].prd_maodeobra_total ? r[i].prd_maodeobra_total.toLocaleString('pt-BR', {minimumFractionDigits: 3, style: 'currency', currency: 'BRL'}) : 0),
          Custo_Direto: (r[i].prd_custodireto_total ? r[i].prd_custodireto_total.toLocaleString('pt-BR', {minimumFractionDigits: 3, style: 'currency', currency: 'BRL'}) : 0),
          Perda: (r[i].prd_perda ? r[i].prd_perda.toLocaleString('pt-BR', {minimumFractionDigits: 3}) + '%' : 0)
        })
      }
      this.qtdePreparos = (this.prep.length === 1 ? this.prep.length + ' Preparo' : this.prep.length + ' Preparos')
      this.qtdeProdutos = (this.prd.length === 1 ? this.prd.length + ' Produto' : this.prd.length + ' Produtos')
      this.prdOriginal = this.prd
      this.metadataCheck()
    },
    salvaSimplesData: function () {
      this.adicionou = true
      this.maxTbl = 0
      this.vTbl = 0
      // Analisando os dados
      if (this.dataTable.length === 0) {
        alert('Tabela vazia')
        this.adicionou = false
        return false
      }
      var prom = []
      var erros = ''
      for (var i = 0; i < this.dataTable.length; i++) {
        if (typeof this.dataTable[i][2] !== 'undefined' && this.dataTable[i][2] !== '' && this.dataTable[i][2] !== null) {
          this.maxTbl++
        }
      }
      if (erros || this.maxTbl === 0) {
        this.$swal('Atenção', 'Tabela vazia?<br>' + erros, 'error')
        this.adicionou = false
        return false
      } else {
        // Adiciona
        for (var i = 0; i < this.dataTable.length; i++) {
          if (typeof this.dataTable[i][2] !== 'undefined' && this.dataTable[i][2] !== '' && this.dataTable[i][2] !== null) {
            prom.push(this.adicionarMassa(this.dataTable[i]))
          }
        }
        Promise.all(prom).then(
          (r) => {
            console.log(r)
            for (var v = 0; v < r.length; v++) {
              if (r[v] === 0) {
                erros += 'Linha ' + (v+1) + ' não foi adicionada<br>'
              } else {
                this.vTbl++
              }
            }
            if (this.vTbl !== this.maxTbl) {
              this.$swal('Importação finalizada!', 'Alguns itens não foram adicionados pois provavelmente já existiam:<div style="overflow:auto;height:100px;"><small>' + erros + '</small></div>', 'warning')
              this.adicionou = false
              this.recarregar()
              return false
            } else {
              this.$notify({
                type: 'success',
                title: 'Importação Finalizada',
                text: 'Todos os itens foram adicionados'
              })
              this.adicionou = false
              this.dataTable = []
              this.recarregar()
              return false
            }
          },
          (r) => {
            console.log(r)
            alert('Erro ao processar planilha')
            this.adicionou = false
            return false
          }
        )
      }
    },
    showModalSimples: function () {
      this.$refs['modal-simples'].show()
    },
    removeSimplesData: function () {
      this.dataTable = []
      this.vTbl = 0
    },
    adicionarMassa: function (p) {
      return this.campo('pro_Produto', 'prd_ID', '(prd_name = ' + p[2] + ')').then(
        (response) => {
          if (typeof response !== 'undefined') {
            return 0
          } else {
            return 0
          }
        }, (response) => {
          if (p[2] === '' || p[4] === '') {
            return 0
          }
          if (p[0] === '' || p[0] === null) {
            p[0] = 'Padrão'
          }
          // Tipo é sempre produto
          p[4] = 1
          
          // Verifica se a linha existe
          var prod = {}
          return this.campo('pro_Produto_Linha', 'pln_ID', '(pln_name = ' + p[0] + ')').then(
            (pln) => {
              // Existe
              if (pln > 0) {
                prod = {resource: [ {pln_ID: pln, prd_tipo: p[4], prd_name: p[2], prd_code: p[1], prd_descr: p[3], prd_active: 1} ]}
                return this.inserir('pro_Produto', prod, '', '1').then(
                  (r) => {
                    // Se for pre-preparo, cria o material associado ao produto
                    if (p[4] === 2 || p[4] === 3) {
                      this.inserir('pro_Material', {resource: [{ prd_ID: r[Object.keys(r)[0]], mat_tipo_ID: 1, mat_name: p[2] }]}, '', '1')
                    }
                    return 1
                  },
                  (r) => {
                    return 0
                  }
                )
              } else {
                console.log('Falha na busca do pln?')
              }
            },
            (pln) => {
              // Nao existe
              return this.inserir('pro_Produto_Linha', {resource: [{ pln_name: p[0], pln_active: 1 }]}, '', '1').then(
                (plnn) => {
                  prod = {resource: [ {pln_ID: plnn[Object.keys(plnn)[0]], prd_tipo: p[4], prd_name: p[2], prd_code: p[1], prd_descr: p[3], prd_active: 1} ]}
                  return this.inserir('pro_Produto', prod, '', '1').then(
                    (r) => {
                      // Se for pre-preparo, cria o material associado ao produto
                      if (p[4] === 2 || p[4] === 3) {
                        this.inserir('pro_Material', {resource: [{ prd_ID: r[Object.keys(r)[0]], mat_tipo_ID: 1, mat_name: p[2] }]}, '', '1')
                      }
                      return 1
                    },
                    (r) => {
                      return 0
                    }
                  )
                },
                (plnn) => {
                  console.log('Falha ao inserir?')
                  return 0
                }
              )
            }
          )
        }
      )
    },
    reativarItem: function (i) {
      this.atualizar('pro_Produto', {resource: [ {prd_active: 1 } ]}, 'prd_ID = ' + String(i)).then(
        (r) => {
          this.ativa = 0
          this.verificaSessao()
        },
        (r) => {
          this.verificaSessao()
        }
      ) 
    },
    dup: function (p) {
      this.$swal.fire({
        title: 'Qual ação deseja executar?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: `Apenas duplicar`,
        denyButtonText: `Copiar como PREPARO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.duplicando(p)
        } else if (result.isDenied) {
          this.duplicando(p, 2)
        }
      })
    },
    duplicando: function (p, preparo) {
      this.loading = true
      this.campos('pro_Produto','prd_ID = ' + p).then(
        (pr) => {
          delete pr.prd_ID
          if (preparo === 2) {
            pr.prd_tipo = 2
            pr.prd_name = pr.prd_name + ' (Cópia Preparo)'
          } else {
            pr.prd_name = pr.prd_name + ' (Cópia)'
          }
          if (this.maxProd > 0 && this.maxProd <= this.prd.length) {
            this.$swal('Você atingiu o limite máximo de produtos/preparos cadastrados para o seu plano.')
            return false;
          }
          this.inserir('pro_Produto', {resource: [ pr ]}, null, '1').then(
            (np) => {
              // Se for pré-preparo, tem que duplicar o registro em materiais
              var novoprod = np[Object.keys(np)[0]]
              if (pr.prd_tipo === 2 || pr.prd_tipo === 3) {
                var pre = {
                  prd_ID: novoprod,
                  mat_tipo_ID: 1,
                  mat_name: pr.prd_name,
                  mat_value: 0,
                  mat_total: 0,
                  mat_active: 1
                }
                this.inserir('pro_Material', {resource: [ pre ]}, null, '1')
              }
              this.listar('pro_Produto_Material', 'pmt_ID', 'prd_ID = ' + p).then(
                (pm) => {
                  var prom = []
                  for (var i = 0; i < pm.length; i++) {
                    delete pm[i].pmt_ID
                    pm[i].prd_ID = novoprod
                    prom.push(this.inserir('pro_Produto_Material', {resource: [ pm[i] ]}, null, '1'))
                  }
                  this.listar('pro_Produto_Maodeobra', 'pmo_ID', 'prd_ID = ' + p).then(
                    (po) => {
                      var prom2 = []
                      for (var i = 0; i < po.length; i++) {
                        delete po[i].pmo_ID
                        po[i].prd_ID = novoprod
                        prom2.push(this.inserir('pro_Produto_Maodeobra', {resource: [ po[i] ]}, null, '1'))
                      }
                      Promise.all(prom, prom2).then(
                        (resp) => {
                          // Verifica se é um produto de pré-preparo e atualiza o valor
                          this.campo('pro_Material', 'mat_ID', '(prd_ID = ' + novoprod + ')').then(
                            (x) => {
                              if (x > 0) {
                                console.log("item duplicado com sucesso")
                                // Pega o total de material e insumos
                                this.campos('filtro_Produto', '(prd_ID = ' + novoprod + ')').then(
                                  (r) => {
                                    // Atualiza no pro_Material
                                    if (preparo === 2) {
                                      this.atualizar('pro_Material', {resource: [ {mat_custohora: r.prd_insumos_total, mat_custominuto: r.prd_maodeobra_total, mat_total: r.prd_custodireto_total, mat_value: r.prd_custodireto_total} ]}, '(mat_ID = ' + x + ')', '', '1')
                                      this.loading = false
                                    } else {
                                      this.atualizar('pro_Material', {resource: [ {mat_total: r.prd_custodireto_total, mat_value: r.prd_custodireto_total} ]}, '(mat_ID = ' + x + ')', '', '1')
                                      this.verificaSessao()
                                      this.loading = false
                                    }
                                  },
                                  (r) => {
                                    console.log("item duplicado")
                                    if (!preparo) { this.verificaSessao() }
                                    this.loading = false
                                  }
                                )
                              }
                            },
                            (x) => {
                              console.log("item dup")
                              if (!preparo) { this.verificaSessao() }
                              this.loading = false
                            }
                          )
                        },
                        (resp) => {
                          // Verifica se é um produto de pré-preparo e atualiza o valor
                          this.campo('pro_Material', 'mat_ID', '(prd_ID = ' + novoprod + ')').then(
                            (x) => {
                              if (x > 0) {
                                // Pega o total de material e insumos
                                this.campos('filtro_Produto', '(prd_ID = ' + novoprod + ')').then(
                                  (r) => {
                                    // Atualiza no pro_Material
                                    if (preparo === 2) {
                                      this.atualizar('pro_Material', {resource: [ {mat_custohora: r.prd_insumos_total, mat_custominuto: r.prd_maodeobra_total, mat_total: r.prd_custodireto_total, mat_value: r.prd_custodireto_total} ]}, '(mat_ID = ' + x + ')', '', '1')
                                      this.loading = false
                                    } else {
                                      this.atualizar('pro_Material', {resource: [ {mat_total: r.prd_custodireto_total, mat_value: r.prd_custodireto_total} ]}, '(mat_ID = ' + x + ')', '', '1')
                                      this.verificaSessao()
                                      this.loading = false
                                    }
                                  },
                                  (r) => {
                                    if (!preparo) { this.verificaSessao() }
                                    this.loading = false
                                  }
                                )
                              }
                            },
                            (x) => {
                              if (!preparo) { this.verificaSessao() }
                              this.loading = false
                            }
                          )
                        }
                      )
                    },
                    (pm) => {}
                  )
                },
                (pm) => {}
              )
            },
            (np) => {}
          )
        },
        (pr) => {}
      )
    },
    remocaoProduto: function(p) {
      if (confirm('Tem certeza? Esta ação não pode ser desfeita!')) {
        var usoParams = {
          g: this.$session.get('grupo'),
          p: p
        }
        this.$http.post(this.URLApp + 'tabelas/removerProduto', usoParams).then(
          (rr) => {
            this.$swal(rr.body)
            this.verificaSessao()
          },
          (rr) => {
            console.log(rr)
            alert('Falha ao remover produto...')
          }
        )
      }
    },
    metadataCheck: function () {
      this.comentarios = ''
      this.mcminima = 0
      this.loading = false
    },
    p: function () {
      this.$router.push('/configuracoes/produtos')
    },
    prp: function () {
      this.$router.push('/configuracoes/preparos')
    },
    prepa: function () {
      this.$router.push('/preparo')
    },
    i: function (index) {
      this.myInsumo = true
      this.idinsumo = this.prd[index].prd_ID
      // var p = this.prd[index].prd_ID
      // this.$router.push('/tabelas/produtosmateriais/' + p)
    },
    mo: function (index) {
      this.myMo = true
      this.idmo = this.prd[index].prd_ID
      //var p = this.prd[index].prd_ID
      //this.$router.push('/tabelas/maodeobrauso/' + p)
    },
    i2: function (index) {
      this.myInsumo = true
      this.idinsumo = this.prep[index].prd_ID
      // var p = this.prep[index].prd_ID
      // this.$router.push('/tabelas/produtosmateriais/' + p)
    },
    mo2: function (index) {
      this.myMo = true
      this.idmo = this.prep[index].prd_ID
      // var p = this.prep[index].prd_ID
      // this.$router.push('/tabelas/maodeobrauso/' + p)
    },
    verificaFaturamento: function () {
      // Ve qual encargo ele faz parte
      this.listar('pro_Encargo', 'enc_ID', 'enc_active = 1').then(
        (en) => {
          if (en.length === 0) {
            this.$swal('Grupo de encargos indefinido!', 'Está faltando definir os grupos de encargos na base desta empresa. Acesse o menu REGIME TRIBUTARIO (em Configurações) antes de acessar aqui.', 'warning')
            this.loading = false
            return false
          }
          for (var i = 0; i < en.length; i++) {
            var encargo = en[i].enc_ID
            var encargoNome = en[i].enc_name
            var encTotalImposto = en[i].enc_total_imposto
            if (encargoNome === 'Lucro Real' || encargoNome === 'MEI') {
              this.alertaFaturamento = false
              this.recarregar()
            } else {
              if (encTotalImposto === 0) {
                this.$notify({
                  type: 'error',
                  title: 'Erro ao buscar produtos e preparos (alíquota zerada ou indefinida)',
                  text: 'Confira suas configurações de regime tributário e encargos'
                })
                return false
              }
            }
            // Pega lista em metadados
            this.listar('gp_Metadados', 'id', '(module LIKE impostos)').then(
              (response) => {
                var mes_descr = []
                var mes_valor = []
                var osmeses = ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez']
                var mesAtual = (new Date().getMonth() + 1)
                var fatur_1, fatur_2, fatur_3, fatur_4, fatur_5, fatur_6, fatur_7, fatur_8, fatur_9, fatur_10, fatur_11, fatur_12 = -1
                var anexo_ativo = 0
                var dup = []
                for (var i = 0; i < response.length; i++) {
                  if (dup.includes(response[i].string)) {
                    this.remover('gp_Metadados', 'id = ' + response[i].id, '')
                  } else {
                    if (parseInt(response[i].description) >= 0) {
                      eval('' + response[i].string + ' = parseFloat(' + response[i].description + ');')
                    }
                    if (String(response[i].date_created).indexOf('-01') >= 0 && response[i].string.indexOf('fatur_') >= 0) {
                      mes_descr[response[i].string] = response[i].date_created
                      mes_valor[(new Date(response[i].date_created).getMonth() + 1)] = parseFloat(response[i].description)
                    } else {
                      mes_descr[response[i].string] = null
                    }
                  }
                  dup.push(response[i].string)
                }
                // Se não existe fatur_1, nem prossegue
                if (fatur_1 === -1) {
                  this.$swal('Faturamento mensal não definido', 'Acesse o menu REGIME TRIBUTÁRIO (em Configurações) para que defina o faturamento mês a mês de sua empresa.', 'warning')
                  this.loading = false
                  return false
                }

                // Constroi um novo array para atualizar a planilha
                var faturNovo = {
                  'fatur_1': {'data': this.corrigeMes(mesAtual - 1), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 1).split('-')[1])]},
                  'fatur_2': {'data': this.corrigeMes(mesAtual - 2), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 2).split('-')[1])]},
                  'fatur_3': {'data': this.corrigeMes(mesAtual - 3), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 3).split('-')[1])]},
                  'fatur_4': {'data': this.corrigeMes(mesAtual - 4), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 4).split('-')[1])]},
                  'fatur_5': {'data': this.corrigeMes(mesAtual - 5), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 5).split('-')[1])]},
                  'fatur_6': {'data': this.corrigeMes(mesAtual - 6), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 6).split('-')[1])]},
                  'fatur_7': {'data': this.corrigeMes(mesAtual - 7), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 7).split('-')[1])]},
                  'fatur_8': {'data': this.corrigeMes(mesAtual - 8), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 8).split('-')[1])]},
                  'fatur_9': {'data': this.corrigeMes(mesAtual - 9), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 9).split('-')[1])]},
                  'fatur_10': {'data': this.corrigeMes(mesAtual - 10), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 10).split('-')[1])]},
                  'fatur_11': {'data': this.corrigeMes(mesAtual - 11), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 11).split('-')[1])]},
                  'fatur_12': {'data': this.corrigeMes(mesAtual - 12), 'valor': mes_valor[parseInt(this.corrigeMes(mesAtual - 12).split('-')[1])]},
                }
                // console.log(faturNovo)
                var prom = []
                for (var v in faturNovo) {
                  prom.push(this.faturUpdate(faturNovo[v], v))
                }
                Promise.all(prom).then(
                  (r) => {
                    var mesfatur = new Date(mes_descr['fatur_1']).getMonth() // Mes anterior na planilha de faturamento
                    var mesanterior = (((new Date().getMonth())-1) < 0 ? 11 : (new Date().getMonth())-1) // Mes anterior real
                    
                    // Verifica se o mes na planilha esta ok e se tem valor de faturamento:
                    if (osmeses[mesfatur] === osmeses[mesanterior] && fatur_1 > 0) {
                      this.alertaFaturamento = false
                    } else {
                      this.alertaFaturamento = true
                    }
                    this.recarregar()
                  },
                  (r) => {
                    this.alertaFaturamento = true
                    this.recarregar()
                  }
                )
              },
              (response) => {
                this.$swal('Falha ao buscar o faturamento da empresa', 'Acesse o menu REGIME TRIBUTÁRIO (em Configurações) e confirme as informações.', 'warning')
                this.loading = false
                return false
              }
            )
          }
        },
        (en) => {
          this.$swal('Grupo de encargos indefinido!', 'Está faltando definir os grupos de encargos na base desta empresa. Acesse o menu REGIME TRIBUTÁRIO (em Configurações) antes de acessar aqui.', 'warning')
          this.loading = false
          return false
        }
      )
    },
    corrigeMes: function (data) {
      // Pega o ano
      var ano = new Date().getFullYear()
      // Corrige o mes
      if (data <= 0) {
        if (data === 0) {
          data = 12
          ano = ano - 1
        } else {
          if (data === -12) {
            data = 12
            ano = ano - 2
          } else {
            data = 12 - ((data) * (-1))
            ano = ano - 1
          }
        }
      }
      return String(ano) + '-' + String(data).padStart(2,'0') + '-01'
    },
    faturUpdate: function (valores, variavel) {
      if (typeof valores.valor === 'undefined' || valores.valor === 0 || valores.valor === '' || valores.valor === 'undefined') {
        valores.valor = 0
      }
      this.campos('gp_Metadados', '(string LIKE ' + variavel + ') AND (module LIKE impostos)').then(
        (response) => {
          if (typeof response !== 'undefined') { // && parseFloat(response.description) !== valores.valor) {
            // Atualiza (só se for diferente)
            if (parseFloat(response.description) !== parseFloat(valores.valor) || valores.data !== response.date_created) {
              this.atualizar('gp_Metadados', {resource: [ {string: variavel, module: 'impostos', description: valores.valor, date_created: valores.data} ]}, 'id = ' + response.id, '', '1')
            }
          }
          if (typeof response === 'undefined') {
            // Nao existe. Cria
            this.inserir('gp_Metadados', {resource: [ {string: variavel, module: 'impostos', description: valores.valor, date_created: valores.data} ]}, '', '1')
          }
        }, 
        (response) => {
          // Nao existe. Cria.
          this.inserir('gp_Metadados', {resource: [ {string: variavel, module: 'impostos', description: valores.valor, date_created: valores.data} ]}, '', '1')
        }
      )
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    },
    searchItems() {
      if (this.filter === '') {
        this.prd = this.prdOriginal
        return
      }
      var searchedItens = []
      var campo1 = ''
      var campo2 = ''
      var campo3 = ''
      for (var i = 0; i < this.prdOriginal.length; i++) {
        campo1 = this.prdOriginal[i].prd_name ? String(this.prdOriginal[i].prd_name).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : ''
        campo2 = this.prdOriginal[i].prd_code ? String(this.prdOriginal[i].prd_code).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : ''
        campo3 = this.prdOriginal[i].prd_linha_name ? String(this.prdOriginal[i].prd_linha_name).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") : ''
        if (campo1.indexOf(this.filter.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0 || campo2.indexOf(this.filter.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0 || campo3.indexOf(this.filter.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0) {
          searchedItens.push(this.prdOriginal[i])
        }
      }
      this.prd = searchedItens
    }
  },
  components: {
    Loading,
    VueAutonumeric,
    Insumo,
    Maodeobra,
    HotTable
  }
}
</script>